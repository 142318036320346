// Generated by Framer (83eb5d8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import DotLottie from "https://framerusercontent.com/modules/jfK7C7JmdHGaVBsvt1V7/G0lF2a2mezep3QZSQZwm/DotLottie.js";
const DotLottieFonts = getFonts(DotLottie);
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["osy8vLm6G"];

const serializationHash = "framer-6mWcd"

const variantClassNames = {osy8vLm6G: "framer-v-gaddiq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const animation = {opacity: 0, rotate: 0, scale: 1, x: 0, y: 50}

const transition1 = {delay: 0, duration: 0.8, ease: [0.44, 0, 0.56, 1], type: "tween"}

const animation1 = {opacity: 0, rotate: 0, scale: 1, transition: transition1, x: 0, y: 50}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, tap, width, ...props}) => { return {...props, AL_WmEF8U: tap ?? props.AL_WmEF8U, hCCc66qCm: link ?? props.hCCc66qCm ?? "https://lottie.host/d15c16c4-dd4b-4cfa-908d-53229a65c87f/9bl4HBEiPr.lottie"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap?: any;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, AL_WmEF8U, hCCc66qCm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "osy8vLm6G", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTaprqhx3t = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (AL_WmEF8U) {
const res = await AL_WmEF8U(...args);
if (res === false) return false;
}
})

const transforms = restProps.transformTemplate?.(undefined, "") ?? ""

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><MotionDivWithFX {...restProps} __framer__animate={{transition: transition1}} __framer__animateOnce __framer__enter={animation} __framer__exit={animation1} __framer__styleAppearEffectEnabled __framer__threshold={0} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-gaddiq", className, classNames)} data-framer-name={"Default"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"osy8vLm6G"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTaprqhx3t} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}} transformTemplate={(_, t) => `${transforms} perspective(1200px) ${t}`}><motion.div className={"framer-1bvs1xw-container"} layoutDependency={layoutDependency} layoutId={"rCQpWdIEw-container"}><DotLottie autoplay background={"var(--token-053cd767-eee8-48bc-912d-e4ad11d173ab, rgb(174, 182, 190)) /* {\"name\":\"Background/Light\"} */"} controls={false} direction={"1"} height={"100%"} hover={false} id={"rCQpWdIEw"} layoutId={"rCQpWdIEw"} loop progress={0} speed={1} srcType={"url"} srcUrl={hCCc66qCm} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></MotionDivWithFX></Transition>
</LayoutGroup>)

});

const css = [".framer-6mWcd[data-border=\"true\"]::after, .framer-6mWcd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6mWcd.framer-q7k7j2, .framer-6mWcd .framer-q7k7j2 { display: block; }", ".framer-6mWcd.framer-gaddiq { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 585px; justify-content: center; max-width: 800px; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 800px; will-change: var(--framer-will-change-override, transform); }", ".framer-6mWcd .framer-1bvs1xw-container { aspect-ratio: 1.3675213675213675 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 585px); position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6mWcd.framer-gaddiq { gap: 0px; } .framer-6mWcd.framer-gaddiq > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-6mWcd.framer-gaddiq > :first-child { margin-left: 0px; } .framer-6mWcd.framer-gaddiq > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 585
 * @framerIntrinsicWidth 800
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"],"constraints":[null,"800px",null,null]}}}
 * @framerVariables {"AL_WmEF8U":"tap","hCCc66qCm":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FrameryuHvtySdS: React.ComponentType<Props> = withCSS(Component, css, "framer-6mWcd") as typeof Component;
export default FrameryuHvtySdS;

FrameryuHvtySdS.displayName = "Lottie + overlay";

FrameryuHvtySdS.defaultProps = {height: 585, width: 800};

addPropertyControls(FrameryuHvtySdS, {AL_WmEF8U: {title: "Tap", type: ControlType.EventHandler}, hCCc66qCm: {defaultValue: "https://lottie.host/d15c16c4-dd4b-4cfa-908d-53229a65c87f/9bl4HBEiPr.lottie", title: "Link", type: ControlType.String}} as any)

addFonts(FrameryuHvtySdS, [...DotLottieFonts])